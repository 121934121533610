// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VLinearProgress_IhmVg{height:2px;overflow:hidden;position:relative;transform:scaleZ(1);width:100%}.VLinearProgress_IhmVg._reverse_quWK4 .model_rm2C8,.VLinearProgress_IhmVg._reverse_quWK4 .track_sxiAb{transform-origin:0 100%}.VLinearProgress_IhmVg._primary_LzDFy .model_rm2C8{background:#9971ff}.VLinearProgress_IhmVg._track-transparent_YvIIs .track_sxiAb{background-color:#0000}.VLinearProgress_IhmVg._track-gradient_vH-IU .track_sxiAb{background:linear-gradient(90deg,#fcecff,#c58bff 26.34%,#9660f9 50.25%,#804dff 75.52%,#697bf8)}.VLinearProgress_IhmVg._dark_aJEX6 .model_rm2C8{background:#09040d}.model_rm2C8,.track_sxiAb{bottom:0;left:0;position:absolute;right:0;top:0;transform-origin:0 0}.model_rm2C8{transition:transform .3s ease}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VLinearProgress": "VLinearProgress_IhmVg",
	"_reverse": "_reverse_quWK4",
	"model": "model_rm2C8",
	"track": "track_sxiAb",
	"_primary": "_primary_LzDFy",
	"_track-transparent": "_track-transparent_YvIIs",
	"_track-gradient": "_track-gradient_vH-IU",
	"_dark": "_dark_aJEX6"
};
module.exports = ___CSS_LOADER_EXPORT___;


export default {
    name: 'VLinearProgress',

    props: {
        value: {
            type: Number,
            default: 0,
        },

        reverse: {
            type: Boolean,
            default: false,
        },

        trackColor: {
            type: String,
            default: 'primary',
            validator: value => ['transparent', 'gradient'].includes(value),
        },

        color: {
            type: String,
            default: 'transparent',
            validator: value => ['primary', 'dark'].includes(value),
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_track-${this.trackColor}`]]: this.trackColor,
                [this.$style._reverse]: this.reverse,
            }];
        },

        modelStyle() {
            return {
                transform: this.reverse
                    ? `translateX(100%) scale3d(${-(1 - this.value)}, 1, 1)`
                    : `scale3d(${this.value}, 1, 1)`,
            };
        },

        trackStyle() {
            return {
                transform: this.reverse
                    ? 'translateX(100%) scale3d(-1, 1, 1)'
                    : 'scale3d(1, 1, 1)',
            };
        },
    },
};
